import { Link } from 'react-router-dom'
import bb from './assets/bb.png'
const PageNotFound = () => {
    return (
        <>
            <div className="container">
                <div className='notFound_err_handle'>
                    <div className='text-center mt-5'>
                        <img src={bb} className="logo" height={60} width={50} />
                    </div>
                    <p className="zoom-area"><b>Opps!</b> Page not Found! </p>
                    <section className="error-container">
                        <span className="four"><span className="screen-reader-text">4</span></span>
                        <span className="zero"><span className="screen-reader-text">0</span></span>
                        <span className="four"><span className="screen-reader-text">4</span></span>
                    </section>

                    <div className='text-center'>
                        <Link className='text-center btn btn-lg btn-outline-secondary' to='/'>Home page</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PageNotFound