import React from 'react'
import './portfolio.css'

import bb from '../assets/bb.png'
import portfolio_img_right from '../assets/portfolio-image.png'

import design_icon from '../assets/design_icon.svg'
import develop_icon from '../assets/develop_icon.png'
import hire_icon from '../assets/hire_icon.png'


import pc1 from '../assets/1pc.png'
import pc2 from '../assets/2pc.png'
import pc3 from '../assets/3pc.png'
import pc4 from '../assets/pc4.png'
import pc5 from '../assets/pc5.png'
import pc6 from '../assets/pc6.png'
import pc7 from '../assets/pc7.png'
import pc8 from '../assets/pc8.png'
import pc9 from '../assets/pc9.png'
import pc10 from '../assets/pc10.png'
import pc11 from '../assets/pc11.png'
import { Link } from 'react-router-dom'


const Portfolio = () => {

    const postArr = [
        {
            img: pc1,
            name: "Pickawood",
            link: 'https://www.pickawood.com/eu/',
            down: ['Website', 'UI/UX', 'Development']
        },
        {
            img: pc10,
            name: "Furfaro Architects",
            link: 'https://www.furfaroarchitects.com.au/',
            down: ['Website', 'UI/UX', 'Development']
        },
        {
            img: pc2,
            name: "Apple AirPods (3rd generation)",
            link: 'https://www.apple.com/airpods-3rd-generation/',
            down: ['Website', 'UI/UX']
        },
        {
            img: pc3,
            name: "Oyster",
            link: 'https://oysteryachts.com/',
            down: ['Website', 'UI/UX', 'Development']
        },
        {
            img: pc4,
            name: "Netflix job",
            link: 'https://jobs.netflix.com/',
            down: ['Website', 'UI/UX', 'Development']
        },
        {
            img: pc5,
            name: "Kisaan Krushi Kendra (E-commerce + CRM) (Web + App)",
            link: 'https://kisaankrushikendra.com/',
            down: ['Web/App', 'UI/UX', 'Development']
        },
        {
            img: pc6,
            name: "Satsabha by Satdham (Attendance system) (Web + App)",
            link: 'https://satsabha.bitbrains.in/',
            down: ['Web/App', 'UI/UX', 'Development']
        },
        {
            img: pc11,
            name: "Sivo",
            link: 'https://www.sivo.com/',
            down: ['Website', 'UI/UX', 'Development']
        },
        {
            img: pc8,
            name: "E.Ocean",
            link: 'https://eocean.net/',
            down: ['Website', 'UI/UX', 'Development']
        },
        {
            img: pc9,
            name: "GunDogsDirect",
            link: 'https://gundogsdirect.co.uk/',
            down: ['Website', 'UI/UX', 'Development']
        },

        {
            img: pc7,
            name: "Markitplace",
            link: 'https://www.markitplaceonline.com/',
            down: ['Website', 'UI/UX', 'Development']
        },
    ]
    return (
        <div className="portfolio_page">

            <section id="header" className="header">
                <nav className="navbar navbar-expand-lg fixed-top navigation-wrap">
                    <div className="container">
                        <a className="navbar-brand" href="/">
                            <img src={bb} alt="logo" className="img-fluid m-3" height="30px"
                                width="30px" />
                            <span className="bitbrains text-white">Bitbrains.In</span>
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span><i className='bx bx-menu navbar-toggler text-white fs-2'></i></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 cb-1">
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" href="/">Home</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="/#service">Service</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="./index.html#technologies">technologies</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="./portfolio.html">Portfolio</a>
                                </li> */}
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="#">Company</a>
                                </li>  */}
                                <li className="nav-item d-flex justify-content-center align-items-center">
                                    <a href='/quote'>
                                        <button className=" btn btn-main" type="submit">Request for Quote</button>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </section>


            <section class="portfolio">
                <div class="project">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-12 col-sm-12 col-md-6">
                                <div class="mx-auto mx-md-auto center-content">
                                    <h1 class="mb-5">Portfolio</h1>
                                    <span class="mb-5">Explore our diverse portfolio of innovative projects,
                                        showcasing our expertise across various industries
                                        and technologies.</span>
                                </div>

                            </div>

                            <div class="col-12 col-sm-12 col-md-6">
                                <div class="d-flex justify-content-center align-items-center">
                                    <img src={portfolio_img_right} class="img-fluid p-img" alt="portfolio-image" height="458px"
                                        width="467px" id="project_home_img_right" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <hr class="hr_project" />

                <div class="project-1">
                    <div class="container">
                        <h3 class="">ONE OF THE QUALITY SERVICES</h3>
                        <div class="row g-5">
                            <div class="col-12 col-sm-12 col-md-4">
                                <div class="d-flex flex-column mt-3 justify-content-center">
                                    <img src={design_icon} class="img-fluid" height="50px" width="50px" />
                                    <h3>Designing</h3>
                                    <p>Creating expansional
                                        user experience for
                                        Seamless services</p>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4">
                                <div class="d-flex flex-column justify-content-center">
                                    <img src={develop_icon} class="img-fluid" height="50px" width="50px" />
                                    <h3>Development</h3>
                                    <p>Empower your brand's journey
                                        with our innovative solutions
                                        and expertise</p>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4">
                                <div class="d-flex flex-column justify-content-center">
                                    <img src={hire_icon} class="img-fluid" height="50px" width="50px" />
                                    <h3>Hire developers team</h3>
                                    <p>We provide talent pool and efficient professionals developers to build your next app.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <hr class="hr_project" />

            <section class="portfolio_home mt-5">
                <div class="container">
                    <div class="row">

                        {
                            postArr.map((value, index) => {
                                return (
                                    <div className="col-12 col-sm-12 col-md-4 mb-4" key={index}>
                                        <div className="card-box p-3">
                                            <a href={`${value.link}`} target='_blank'>
                                                <img src={value.img} className="img-fluid" style={{ borderRadius: "12px" }} />
                                            </a>
                                            <h4 className="my-4">
                                                {value.name}
                                            </h4>
                                            <div className="keyword px-3">
                                                <div className="row">
                                                    {
                                                        value.down.length === 2
                                                            ? value.down.map((value1, k) => {
                                                                return (
                                                                    <p className="col text-center mb-2" key={k}>{value1}</p>
                                                                )
                                                            })
                                                            : <>
                                                                <p className="col text-center mb-2">{value.down[0]}</p>
                                                                <p className="col text-center mb-2">{value.down[1]}</p>
                                                                <p className="col-6 text-center mb-2">{value.down[2]}</p>
                                                            </>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {/* <div className="col-12 col-sm-12 col-md-4 mb-4">
                            <div className="card-box p-3">
                                <a href='https://www.apple.com/airpods-3rd-generation/' target='_blank'>
                                    <img src={pc2} className="img-fluid" style={{ borderRadius: "12px" }} />
                                </a>
                                <h4 className="my-4">Apple AirPods (3rd generation)</h4>
                                <div className="row">
                                    <div className="keyword px-3">
                                        <div className="row">
                                            <p className="col text-center mb-2">UI/UX</p>
                                            <p className="col text-center mb-2">Design</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>



            <div className="footer">
                <div className="container wrapper">
                    <div className="d-flex flex-column align-items-center mb-5">
                        <div className="footer-box d-flex flex-column align-items-center">
                            <img src={bb} className="img-fluid text-center" height="50px" width="50px" />
                            <h2 className="text-center">Let's Connect<br /><span className="color_text text-capitalize text-center">with US.</span></h2>
                            {/* <form>
                                    <input type="email" className="email" placeholder="Insert your email" required />
                                    <button type="submit" className="btn-color">Request Demo</button>
                                </form> */}
                        </div>
                    </div>
                </div>

                <div className="footer-final container">
                    <div className="row g-5">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                            <h3>About</h3>
                            <p>At BitBrains, we pride ourselves on being the top choice for all your digital needs. With years of experience and a passionate team of experts, we go above and beyond to deliver exceptional results. What sets us apart is our unwavering dedication to our clients.</p>
                        </div>


                        <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                            <div className="d-flex flex-column justify-content-center">
                                <h3>Our Service</h3>
                                <p>App Development</p>
                                <p>Web Development</p>
                                <p>ERP & CRM</p>
                                <p>Designing</p>
                                <p>Hire Offshore Developers</p>
                            </div>

                        </div>

                        <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                            <h3>Email</h3>
                            <p>support@bitbrains.in</p>
                        </div>

                        <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                            <h3>Want to talk about your project?
                            </h3>
                            <a className="btn-main mt-4" href="https://calendly.com/vivekbodarya" target='_blank'>Schedual A Call</a>
                        </div>
                    </div>
                </div>
                <div className="copyright container">
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-6 mb-4'>
                            <p className="ms-3 mt-4">Copyright @2024 Bitbrains Infotech</p>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 mb-4 d-flex justify-content-end'>
                            <div className="social-media me-4 mt-2">
                                <a href="https://www.linkedin.com/in/vivekbodarya/" target='_blank'>
                                    <i className='bx bxl-linkedin'></i>
                                </a>
                                <a href="https://www.instagram.com/bitbrains.in" target='_blank'>
                                    <i className='bx bxl-instagram'></i>
                                </a>
                                <a href="https://dribbble.com/vivek_bodarya" target='_blank'>
                                    <i className='bx bxl-dribbble'></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <div class="d-flex justify-content-between align-items-center">
                            <p class="ms-3">Copyright @2024 Bitbrains Infotech</p>
                            <div class="social-media me-4">
                                <a href="https://www.linkedin.com/in/vivekbodarya/" target='_blank'>
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                                <a href="https://www.instagram.com/bitbrains.in" target='_blank'>
                                    <i class='bx bxl-instagram'></i>
                                </a>
                                <a href="https://dribbble.com/vivek_bodarya" target='_blank'>
                                    <i class='bx bxl-dribbble'></i>
                                </a>
                            </div>
                        </div> */}
                </div>
            </div>
        </div>
    )
}

export default Portfolio