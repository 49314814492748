import './home.css'

import React from 'react'
import apple from '../assets/apple.png'
import netflix from '../assets/netflix.png'
import Satdham from '../assets/Satdham.png'
import kissan_krushi from '../assets/kissan_krushi.png'
import pickawood from '../assets/pickawood.png'
import oyster from '../assets/oyster.png'
import markitplace from '../assets/markitplace.png'
import e_ociean from '../assets/e_ociean.gif'
import sivo from '../assets/sivo.svg'
import furfaro from '../assets/furfaro.svg'

import pc1 from '../assets/1pc.png'
import pc2 from '../assets/2pc.png'
import pc3 from '../assets/3pc.png'


import AppDevelopment from '../assets/AppDevelopment.svg'
import development from '../assets/development.svg'
import seo from '../assets/seo.svg'
import hire from '../assets/hire.svg'
import uiux_svg from '../assets/uiux_svg.svg'
import web from '../assets/web.svg'

import react from '../assets/react.svg'
import angular from '../assets/angular.svg'
import vue from '../assets/vue.svg'
import nextjs from '../assets/nextjs.svg'


import nodejs from '../assets/nodejs.svg'
import php from '../assets/php.svg'
import dotnet from '../assets/dotnet.svg'
import python from '../assets/python.svg'


import android from '../assets/android.svg'
import apple2 from '../assets/apple2.png'
// import dotnet from '../assets/dotnet.svg'
import flutter from '../assets/flutter.svg'

import mongodb from '../assets/mongodb.svg'
import mysql from '../assets/mysql.svg'
import ms_sql from '../assets/ms_sql.svg'
import SQLite from '../assets/SQLite.svg'
import PostgreSQL from '../assets/PostgreSQL.svg'
import Firebase from '../assets/Firebase.svg'

import aws from '../assets/aws.svg'
import cicd from '../assets/CI-CD.svg'
import Docker from '../assets/Docker.svg'

import MicrosoftTeam from '../assets/MicrosoftTeam.svg'
import ClickUp from '../assets/ClickUp.svg'
import slack from '../assets/slack.svg'

import bb from '../assets/bb.png'
import { Link } from 'react-router-dom'
import Contactus from './Contactus'

const Home = () => {
  return (
    <div className="index_page">
      <section id="header" className="header index_header">
        <nav className="navbar navbar-expand-lg fixed-top navigation-wrap">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img src={bb} alt="logo" className="img-fluid m-3"
                height="30px" width="30px" />
              <span className="bitbrains text-white">Bitbrains.In</span></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
              <span><i className='bx bx-menu navbar-toggler text-white fs-2'></i></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 cb-1">
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="#">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#service">Service</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#technologies">technologies</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contactus">Contact us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href='/portfolio'>Portfolio</a>
                </li>
                {/* <li class="nav-item">
                  <a class="nav-link" href="#">Company</a>
                </li>  */}
                <li className="nav-item d-flex justify-content-center align-items-center">
                  <a href='/quote'>
                    <button className=" btn btn-main" type="submit">Request for Quote</button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>

      <section className="home-content">
        <div className="home">
          <div className="container mt-5">
            <h4 className="text-center">A <span style={{ color: "#C4A7E8" }} className="fw-bold">multi-tasking</span> company
            </h4>
            <h2 className="text-center fw-bold text-capitalize">BRING YOUR IDEA TO REALITY</h2>
            <p className="text-center">We help to you provide best digital experience and build innovative
              solution for your business. Let’s create business together.</p>
          </div>
          {/* <img src="assets/home_img.png" class="img-fluid mt-5" height="663px">  */}
        </div>
      </section>


      <div className="container-fluid trust_section">
        {/* <h1 className="text-center  pt-3 mb-5  trust_h1">
      TRUSTED BY
    </h1> */}
        <div className="row d-flex justify-content-center align-items-center py-5 trust_row">
          <marquee scrollamount="10">
            <div className=" d-flex align-items-center">
              <div className="me-5">
                <img src={apple} alt="Apple" height="50px" width="41px" />
              </div>
              <div className="me-5">
                <img src={netflix} alt="Netflix" height="40px" width="140px" />
              </div>
              <div className="me-5">
                <img src={Satdham} alt="Satdham" height="40px" width="121px" />
              </div>
              <div className="me-5">
                <img src={kissan_krushi} alt="Kissan Krushi Kendra" height="80px" width="140px" />
              </div>
              <div className="me-5">
                <img src={pickawood} alt="Pick a wood" height="40px" width="140px" />
              </div>
              <div className="me-5">
                <img src={oyster} alt="Oyster" height="50px" width="140px" />
              </div>
              <div className="me-2">
                <img src={markitplace} alt="Markit place" height="40px" width="180px" />
              </div>
              <div className="">
                <img src={sivo} alt="Markit place" height="35px" width="180px" />
              </div>
              <div className="me-3" style={{ marginLeft: '-30px' }}>
                <img src={furfaro} alt="Markit place" height="50px" width="180px" />
              </div>
              <div className="me-5">
                <img src={e_ociean} alt="E-ociean" className="img-fluid" height="40px" width="46px" />
                <span style={{ fontSize: "18px" }}>e.ocean</span>
              </div>
            </div>
          </marquee>


          <marquee scrollamount="10" className="mt-5" direction="right">
            <div className=" d-flex align-items-center">
              <div className="me-5">
                <img src={apple} alt="Apple" height="50px" width="41px" />
              </div>
              <div className="me-5">
                <img src={netflix} alt="Netflix" height="40px" width="140px" />
              </div>
              <div className="me-5">
                <img src={Satdham} alt="Satdham" height="40px" width="121px" />
              </div>
              <div className="me-5">
                <img src={kissan_krushi} alt="Kissan Krushi Kendra" height="80px" width="140px" />
              </div>
              <div className="me-5">
                <img src={pickawood} alt="Pick a wood" height="40px" width="140px" />
              </div>
              <div className="me-5">
                <img src={oyster} alt="Oyster" height="50px" width="140px" />
              </div>
              <div className="me-2">
                <img src={markitplace} alt="Markit place" height="40px" width="180px" />
              </div>
              <div className="">
                <img src={sivo} alt="Markit place" height="35px" width="180px" />
              </div>
              <div className="me-3" style={{ marginLeft: '-30px' }}>
                <img src={furfaro} alt="Markit place" height="50px" width="180px" />
              </div>
              <div className="me-5">
                <img src={e_ociean} alt="E-ociean" className="img-fluid" height="40px" width="46px" />
                <span style={{ fontSize: "18px" }}>e.ocean</span>
              </div>
            </div>
          </marquee>
        </div>
      </div>



      <section className="portfolio_home">
        <div className="container">
          <div className="d-flex justify-content-between my-5">
            <h3>Some selected projects</h3>
            <div>
              <Link to="/portfolio">
                <button className=" btn btn-see-all">See all</button>
              </Link>
            </div>
            {/* <button className="btn  p-3">
          See all
        </button>  */}
          </div>
          <div className="row ">
            <div className="col-12 col-sm-12 col-md-4 mb-3">
              <div className="card-box p-3">
                <a href='https://www.pickawood.com/eu/' target='_blank'>
                  <img src={pc1} className="img-fluid" style={{ borderRadius: "12px" }} />
                </a>
                <h4 className="my-4">
                  Pickawood
                </h4>
                <div className="keyword px-3">
                  <div className="row">
                    <p className="col text-center mb-2">Website</p>
                    <p className="col text-center mb-2">UI/UX</p>
                    <p className="col-6 text-center mb-2">Development</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-4 mb-3">
              <div className="card-box p-3">
                <a href='https://www.apple.com/airpods-3rd-generation/' target='_blank'>
                  <img src={pc2} className="img-fluid" style={{ borderRadius: "12px" }} />
                </a>
                <h4 className="my-4">Apple AirPods (3rd generation)</h4>
                <div className="row">
                  <div className="keyword px-3">
                    <div className="row">
                      <p className="col text-center mb-2">UI/UX</p>
                      <p className="col text-center mb-2">Design</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-4 mb-3">
              <div className="card-box p-3">
                <a href='https://oysteryachts.com/' target='_blank'>
                  <img src={pc3} className="img-fluid" style={{ borderRadius: "12px" }} />
                </a>
                <h4 className="my-4">Oyster</h4>
                <div className="keyword px-3">
                  <div className="row">
                    <p className="col text-center mb-2">Website</p>
                    <p className="col text-center mb-2">UI/UX</p>
                    <p className="col-6 text-center mb-2">Development</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="service" id="service">
        <div className="container">
          <h3 className="our_services">OUR SERVICES</h3>
          <div className="row">

            <div className="col-12 col-sm-12 col-md-4 mb-5 service_card">
              <div className="d-flex flex-column justify-content-center">
                <img src={AppDevelopment} className="img-fluid" height="35px" width="35px" />
                <h3>App Development</h3>
                <p>We specialize in creating innovative and user-friendly mobile apps to help achieve goals.
                </p>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-4 mb-5 service_card">
              <div className="d-flex flex-column justify-content-center">
                <img src={development} className="img-fluid" height="50px" width="50px" />
                <h3>CRM & ERP</h3>
                <p>We provide services for customized ERP and CRM solutions for your business. Let's easy to
                  manage your business with bitbrains.</p>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-4 mb-5 service_card">
              <div className="d-flex flex-column justify-content-center">
                <img src={seo} className="img-fluid" height="50px" width="50px" />
                <h3>SEO</h3>
                <p>We provide professional SEO services to improve online visibility, increase website
                  traffic,
                  and enhance search engine ranking for businesses and organizations.</p>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-4 mb-5 service_card">
              <div className="d-flex flex-column justify-content-center">
                <img src={hire} className="img-fluid" height="50px" width="50px" />
                <h3>Hire Offshore Developers</h3>
                <p>We provide software development services through a dedicated and skilled offshore
                  development
                  team across various technologies and frameworks.</p>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-4 mb-5 service_card">
              <div className="d-flex flex-column justify-content-center">
                <img src={uiux_svg} className="img-fluid" height="50px" width="50px" />
                <h3>Designing</h3>
                <p>We offer professional UI/UX design services to enhance the user experience and improve
                  the
                  overall look and feel of websites and mobile applications.</p>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-4 mb-5 service_card">
              <div className="d-flex flex-column justify-content-center">
                <img src={web} className="img-fluid" height="50px" width="50px" />
                <h3>Web Development</h3>
                <p>We provide web services including hosting, streaming, and delivering content to ensure
                  the
                  best online experience for you. We enable Agile and DevOps concepts in development.</p>
              </div>
            </div>

          </div>
        </div>
      </div>




      <div className="tech" id="technologies">
        <div className="container mb-5">

          <h3 className="our_tech">TECHNOLOGIES WE DEPLOY</h3>
          <p>
            Our cutting-edge technologies help in delivering our customers with software solutions that are
            seamless, efficient, and streamlines with their vision.
          </p>
          <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
            <li className="nav-item mb-3 mb-lg-0" role="presentation">
              <button className="nav-link active" id="pills-frontend-tab" data-bs-toggle="pill"
                data-bs-target="#pills-frontend" type="button" role="tab" aria-controls="pills-frontend"
                aria-selected="true">Frontend</button>
            </li>
            <li className="nav-item mb-3 mb-lg-0" role="presentation">
              <button className="nav-link" id="pills-backend-tab" data-bs-toggle="pill"
                data-bs-target="#pills-backend" type="button" role="tab" aria-controls="pills-backend"
                aria-selected="false">Backend</button>
            </li>
            <li className="nav-item mb-3 mb-lg-0" role="presentation">
              <button className="nav-link" id="pills-mobile-tab" data-bs-toggle="pill"
                data-bs-target="#pills-mobile" type="button" role="tab" aria-controls="pills-mobile"
                aria-selected="false">Mobile</button>
            </li>
            <li className="nav-item mb-3 mb-lg-0" role="presentation">
              <button className="nav-link" id="pills-database-tab" data-bs-toggle="pill"
                data-bs-target="#pills-database" type="button" role="tab" aria-controls="pills-database"
                aria-selected="false">Database</button>
            </li>
            <li className="nav-item mb-3 mb-lg-0" role="presentation">
              <button className="nav-link" id="pills-DevOps-tab" data-bs-toggle="pill"
                data-bs-target="#pills-DevOps" type="button" role="tab" aria-controls="pills-DevOps"
                aria-selected="false">DevOps and Clould</button>
            </li>
            <li className="nav-item mb-3 mb-lg-0" role="presentation">
              <button className="nav-link" id="pills-project-tab" data-bs-toggle="pill"
                data-bs-target="#pills-project" type="button" role="tab" aria-controls="pills-project"
                aria-selected="false">Project Management</button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-frontend" role="tabpanel"
              aria-labelledby="pills-frontend-tab" tabIndex="0">
              <div className="row mt-5">
                <div className="col-12 col-sm-2"></div>
                <div className="col-6 col-sm-2 mt-3 mt-sm-0 text-center">
                  <img className="img-fluid" src={react} height="50" width="50" />
                  <div className="mt-3 text-white">React</div>
                </div>
                <div className="col-6 col-sm-2  mt-3 mt-sm-0 text-center">
                  <img className="img-fluid" src={angular} height="50" width="50" />
                  <div className="mt-3 text-white">Angular</div>
                </div>
                <div className="col-6 col-sm-2 mt-5 mt-sm-0 text-center">
                  <img className="img-fluid mt-1" src={vue} height="50" width="50" />
                  <div className="mt-4 text-white">Vue</div>
                </div>
                <div className="col-6 col-sm-2  mt-5 mt-sm-0 text-center">
                  <img className="img-fluid" src={nextjs} height="100" width="100" />
                  <div className="mt-5 text-white">Next</div>
                </div>
                <div className="col-12 col-sm-2"></div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-backend" role="tabpanel" aria-labelledby="pills-backend-tab"
              tabIndex="0">

              <div className="row mt-5">
                <div className="col-12 col-sm-2"></div>
                <div className="col-6 col-sm-2 mt-3 mt-sm-0 text-center">
                  <img className="img-fluid" src={nodejs} height="50" width="50" />
                  <div className="mt-3 text-white">Node</div>
                </div>
                <div className="col-6 col-sm-2  mt-3 mt-sm-0 text-center">
                  <img className="img-fluid" src={php} height="50" width="50" />
                  <div className="mt-3 text-white">PHP</div>
                </div>
                <div className="col-6 col-sm-2 mt-5 mt-sm-0 text-center">
                  <img className="img-fluid mt-0" src={dotnet} height="50" width="50" />
                  <div className="mt-3 text-white">DotNet</div>
                </div>
                <div className="col-6 col-sm-2  mt-5 mt-sm-0 text-center">
                  <img className="img-fluid" src={python} height="50" width="50" />
                  <div className="mt-3 text-white">Python</div>
                </div>
                <div className="col-12 col-sm-2"></div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-mobile" role="tabpanel" aria-labelledby="pills-mobile-tab"
              tabIndex="0">
              <div className="row mt-5">
                <div className="col-12 col-sm-2"></div>
                <div className="col-6 col-sm-2 mt-3 mt-sm-0 text-center">
                  <img className="img-fluid" src={android} height="50" width="50" />
                  <div className="mt-3 text-white">Android</div>
                </div>
                <div className="col-6 col-sm-2  mt-3 mt-sm-0 text-center">
                  <img className="img-fluid" src={apple2} height="40" width="40" />
                  <div className="mt-3 text-white">IOS</div>
                </div>
                <div className="col-6 col-sm-2 mt-5 mt-sm-0 text-center">
                  <img className="img-fluid mt-0" src={react} height="50" width="50" />
                  <div className="mt-3 text-white">React-native</div>
                </div>
                <div className="col-6 col-sm-2  mt-5 mt-sm-0 text-center">
                  <img className="img-fluid mt-1" src={flutter} height="40" width="40" />
                  <div className="mt-3 text-white">Flutter</div>
                </div>
                <div className="col-12 col-sm-2"></div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-database" role="tabpanel" aria-labelledby="pills-database-tab"
              tabIndex="0">
              <div className="row mt-5">
                <div className="col-6 col-sm-2 mt-3 mt-sm-0 text-center">
                  <img className="img-fluid" src={mongodb} height="50" width="50" />
                  <div className="mt-3 text-white">MongoDB</div>
                </div>
                <div className="col-6 col-sm-2  mt-3 mt-sm-0 text-center" style={{ marginTop: "-10px" }}>
                  <img className="img-fluid" src={mysql} height="70" width="70" />
                  <div className="mt-0 text-white">MySQL</div>
                </div>
                <div className="col-6 col-sm-2 mt-5 mt-sm-0 text-center">
                  <img className="img-fluid mt-0" src={ms_sql} height="70" width="70" />
                  <div className="mt-4 text-white">MsSQL</div>
                </div>
                <div className="col-6 col-sm-2  mt-5 mt-sm-0 text-center">
                  <img className="img-fluid mt-1" src={SQLite} height="70" width="70" />
                  <div className="mt-2 text-white">SQLite</div>
                </div>
                <div className="col-6 col-sm-2  mt-5 mt-sm-0 text-center">
                  <img className="img-fluid mt-1" src={PostgreSQL} height="70" width="70" />
                  <div className="mt-3 text-white">PostgreSQL</div>
                </div>
                <div className="col-6 col-sm-2  mt-5 mt-sm-0 text-center">
                  <img className="img-fluid mt-1" src={Firebase} height="70" width="70" />
                  <div className="mt-3 text-white">Firebase</div>
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="pills-DevOps" role="tabpanel" aria-labelledby="pills-DevOps-tab"
              tabIndex="0">
              <div className="row mt-5">
                <div className="col-12 col-sm-12 col-md-3 mt-3 mt-sm-0 text-center"></div>
                <div className="col-6 col-sm-2 mt-3 mt-sm-0 text-center">
                  <img className="img-fluid" src={aws} height="50" width="50" />
                  <div className="mt-3 text-white">AWS</div>
                </div>
                <div className="col-6 col-sm-2  mt-3 mt-sm-0 text-center" style={{ marginTop: "-10px" }}>
                  <img className="img-fluid" src={cicd} height="100" width="100" />
                  <div className="mt-2 text-white">CI-CD</div>
                </div>
                <div className="col-6 col-sm-2 mt-5 mt-sm-0 text-center">
                  <img className="img-fluid mt-0" src={Docker} height="80" width="80" />
                  <div className="mt-4 text-white">Docker</div>
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="pills-project" role="tabpanel" aria-labelledby="pills-project-tab"
              tabIndex="0">
              <div className="row mt-5">
                <div className="col-12 col-sm-12 col-md-3 mt-3 mt-sm-0 text-center"></div>
                <div className="col-6 col-sm-2 mt-3 mt-sm-0 text-center">
                  <img className="img-fluid" src={MicrosoftTeam} height="90" width="90" />
                  <div className="mt-3 text-white">Microsoft Team</div>
                </div>
                <div className="col-6 col-sm-2  mt-3 mt-sm-0 text-center" style={{ marginTop: "-10px" }}>
                  <img className="img-fluid" src={ClickUp} height="100" width="100" />
                  <div className="mt-2 text-white">ClickUp</div>
                </div>
                <div className="col-6 col-sm-2 mt-5 mt-sm-0 text-center">
                  <img className="img-fluid mt-3" src={slack} height="40" width="40" />
                  <div className="mt-4 text-white">Slack</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='contactus'>
        <Contactus />
      </div>
    </div>
  )
}

export default Home