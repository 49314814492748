import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import axios from 'axios'

import bb from '../assets/bb.png'
const base_url = 'https://backbone.bitbrains.in'

const Contactus = () => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    // loader
    const [loader, setLoader] = useState(false)
    // err
    const [err, setErr] = useState('')
    const [Success, setSuccess] = useState('')

    const onSubmit = (value) => {
        setSuccess("")
        setErr("")
        setLoader(true)
        const data = {
            name: value.name,
            email: value.email,
            company: value.company,
            mobile: value.mobile === "" ? "NULL" : value.mobile,
            description: value.description
        }

        const header = {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
        // console.log(header);
        axios.post(base_url + "/req/contact", data, header)
            .then((res) => {
                if (res.status === 200) {
                    setLoader(false)
                    setSuccess(`Hey, ${value.name}!! Your entry submitted successfully, we will connect with you soon as possible!`)
                    reset()
                }
            })
            .catch((err) => {
                if (err?.response?.status === 301) {
                    setSuccess("")
                    setLoader(false)
                    setErr(err.response.data.message || "Opps! Somthing went wrong.")
                } else {
                    setSuccess("")
                    setLoader(false)
                    setErr("Opps! Somthing went wrong.")

                }
            })

    }
    return (
        <>
            <div className="contact">
                <div className="container">
                    <div className="contact-card">
                        <h2 className="text-center">Ask a Question</h2>
                        <form className="contact-form p-3 p-md-0" onSubmit={handleSubmit(onSubmit)}>
                            {
                                Success !== ""
                                    ? <div className="alert alert-success mx-3 mx-md-5 mb-5" role="alert" style={{ backgroundColor: '#000000', color: '#ffffff', borderWidth: '3px' }}>
                                        <i className='bx bxs-check-circle text-success'></i> &nbsp; {Success}
                                    </div>
                                    : ""
                            }
                            {
                                err !== ""
                                    ? <div className="alert alert-danger mx-3 mx-md-5 mb-5" role="alert" style={{ backgroundColor: '#000000', color: '#ffffff', borderWidth: '3px' }}>
                                        <i className='bx bxs-sad text-danger'></i> &nbsp; {err}
                                    </div>
                                    : ""
                            }
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-12 col-md-3">
                                    <input
                                        type="text"
                                        className="form-control your_name"
                                        placeholder="Your name*"
                                        {...register("name", {
                                            required: true,
                                            pattern: /^[a-zA-ZÀ-ÿ\-'. ]{2,30}$/,
                                        })}
                                    />
                                    <small className="text-danger">{errors.name?.type === 'required' && "Name is required!"}</small>
                                    <small className="text-danger">{errors.name?.type === 'pattern' && "Please enter a valid name"}</small>
                                </div>

                                <div className="col-12 col-sm-12 col-md-5">
                                    <input
                                        type="email"
                                        className="form-control email"
                                        placeholder="Your email*"
                                        {...register("email", {
                                            required: true,
                                            pattern: /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/,
                                        })}
                                    />
                                    <small className="text-danger">{errors.email?.type === 'required' && "Email is required!"}</small>
                                    <small className="text-danger">{errors.email?.type === 'pattern' && "Please enter a valid email"}</small>
                                </div>
                                <div className="col-12 col-sm-12 col-md-3">
                                    <input
                                        type="text"
                                        className="form-control company"
                                        placeholder="Company or purpose*"
                                        {...register("company", {
                                            required: true,
                                            pattern: /^[a-zA-Z0-9,@\-_()*!&. ]{2,100}$/,
                                        })}
                                    />
                                    <small className="text-danger">{errors.company?.type === 'required' && "Purpose or company name is required!"}</small>
                                    <small className="text-danger">{errors.company?.type === 'pattern' && "Please enter a valid purpose or company name format"}</small>
                                </div>
                            </div>

                            <div className="row justify-content-center mt-0 mt-md-4">
                                <div className="col-12 col-sm-12 col-md-3">
                                    <input
                                        type="text"
                                        className="form-control your_name"
                                        placeholder="Your mobile"
                                        {...register("mobile", {
                                            pattern: /^\+?[0-9]{1,4}[ -]?\(?[0-9]{1,3}\)?[ -]?[0-9]{3,4}[ -]?[0-9]{4}$/
                                        })}
                                    />
                                    <small className="text-danger">{errors.mobile?.type === 'required' && "Mobile is required!"}</small>
                                    <small className="text-danger">{errors.mobile?.type === 'pattern' && "Please enter a valid mobile format"}</small>
                                </div>
                                <div className="col-12 col-sm-12 col-md-8">
                                    <input
                                        type="text"
                                        className="form-control message"
                                        placeholder="Your message*"
                                        {...register("description", {
                                            required: true,
                                            pattern: /^[^<>\/;=]*$/,
                                        })}
                                    />
                                    <small className="text-danger">{errors.description?.type === 'required' && "Message is required!"}</small>
                                    <small className="text-danger">{errors.description?.type === 'pattern' && "Please enter a valid message format"}</small>
                                </div>
                            </div>

                            <div className="row justify-content-center mt-4">
                                <div className="col-12 col-sm-11  ">
                                    {
                                        loader === true
                                            ? <button className="btn-main mt-3 px-5" disabled>Waiting...</button>
                                            : <button type="submit" className="btn-main mt-3 px-5">Submit</button>
                                    }

                                </div>
                            </div>


                        </form>
                    </div>
                </div>

                <div className="footer">
                    <div className="container wrapper">
                        <div className="d-flex flex-column align-items-center mb-5">
                            <div className="footer-box d-flex flex-column align-items-center">
                                <img src={bb} className="img-fluid text-center" height="50px" width="50px" />
                                <h2 className="text-center">Let's Connect<br /><span className="color_text text-capitalize text-center">with US.</span></h2>
                                {/* <form>
                                    <input type="email" className="email" placeholder="Insert your email" required />
                                    <button type="submit" className="btn-color">Request Demo</button>
                                </form> */}
                            </div>
                        </div>
                    </div>

                    <div className="footer-final container">
                        <div className="row g-5">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                                <h3>About</h3>
                                <p>At BitBrains, we pride ourselves on being the top choice for all your digital needs. With years of experience and a passionate team of experts, we go above and beyond to deliver exceptional results. What sets us apart is our unwavering dedication to our clients.</p>
                            </div>


                            <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                                <div className="d-flex flex-column justify-content-center">
                                    <h3>Our Service</h3>
                                    <p>App Development</p>
                                    <p>Web Development</p>
                                    <p>ERP & CRM</p>
                                    <p>Designing</p>
                                    <p>Hire Offshore Developers</p>
                                </div>

                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                                <h3>Email</h3>
                                <p>support@bitbrains.in</p>
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                                <h3>Want to talk about your project?
                                </h3>
                                <a className="btn-main mt-4" href="https://calendly.com/vivekbodarya" target='_blank'>Schedual A Call</a>
                            </div>
                        </div>
                    </div>
                    <div className="copyright container">
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-6 mb-4'>
                                <p className="ms-3 mt-4">Copyright @2024 Bitbrains Infotech</p>
                            </div>
                            <div className='col-12 col-sm-12 col-md-6 mb-4 d-flex justify-content-end'>
                                <div className="social-media me-4 mt-2">
                                    <a href="https://www.linkedin.com/in/vivekbodarya/" target='_blank'>
                                        <i className='bx bxl-linkedin'></i>
                                    </a>
                                    <a href="https://www.instagram.com/bitbrains.in" target='_blank'>
                                        <i className='bx bxl-instagram'></i>
                                    </a>
                                    <a href="https://dribbble.com/vivek_bodarya" target='_blank'>
                                        <i className='bx bxl-dribbble'></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <div class="d-flex justify-content-between align-items-center">
                            <p class="ms-3">Copyright @2024 Bitbrains Infotech</p>
                            <div class="social-media me-4">
                                <a href="https://www.linkedin.com/in/vivekbodarya/" target='_blank'>
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                                <a href="https://www.instagram.com/bitbrains.in" target='_blank'>
                                    <i class='bx bxl-instagram'></i>
                                </a>
                                <a href="https://dribbble.com/vivek_bodarya" target='_blank'>
                                    <i class='bx bxl-dribbble'></i>
                                </a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contactus