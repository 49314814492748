import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import Portfolio from './components/portfolio/Portfolio';
import PageNotFound from './components/PageNotFound';
import RequestQuote from './components/requestForQuote/RequestQuote';
function App() {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Home />} ></Route>
        <Route path="/portfolio" exact element={<Portfolio />} ></Route>
        <Route path="/quote" exact element={<RequestQuote />} ></Route>
        <Route path="*" exact element={<PageNotFound />} ></Route>

      </Routes>
    </>
  );
}

export default App;
