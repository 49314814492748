import React, { useState } from 'react'
import bb from '../assets/bb.png'
import './quote_style.css'
import { Link } from 'react-router-dom'

import { useForm } from "react-hook-form";
import axios from 'axios'
const base_url = 'https://backbone.bitbrains.in'
// https://backbone.bitbrains.in

const RequestQuote = () => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    // loader
    const [loader, setLoader] = useState(false)
    // err
    const [err, setErr] = useState('')
    const [Success, setSuccess] = useState('')

    const onSubmit = (data) => {
        setSuccess("")
        setErr("")
        setLoader(true)
        // console.log(data);
        const header = {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
        // console.log(header);
        axios.post(base_url + "/req/quote", data, header)
            .then((res) => {
                if (res.status === 200) {
                    setLoader(false)
                    setSuccess(`Hey, ${data.fname}!! Your entry submitted successfully, we will connect with you soon as possible!`)
                    reset()
                }
            })
            .catch((err) => {
                if (err?.response?.status === 301) {
                    setSuccess("")
                    setLoader(false)
                    setErr(err.response.data.message || "Opps! Somthing went wrong.")
                } else {
                    setSuccess("")
                    setLoader(false)
                    setErr("Opps! Somthing went wrong.")

                }
            })
    }


    return (
        <>
            <div class="quote_page">

                <section id="header" class="header">
                    <nav class="navbar navbar-expand-lg fixed-top navigation-wrap">
                        <div class="container">
                            <a class="navbar-brand" href="/">
                                <img src={bb} alt="logo" class="img-fluid m-3" height="30px" width="30px" />
                                <span class="bitbrains text-white">Bitbrains.In</span>
                            </a>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span><i class='bx bx-menu navbar-toggler text-white fs-2'></i></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 cb-1">
                                    <li class="nav-item">
                                        <a class="nav-link" aria-current="page" href="/">Home</a>
                                    </li>
                                    {/* 
                                    <li class="nav-item d-flex justify-content-center align-items-center">
                                        <button class=" btn btn-main" type="submit">Request for Quote</button>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </section>

                <section class="quote">
                    <div class="project">
                        <div class="container">
                            <div class="d-flex flex-column">
                                <h1 class="text-center text-capitalize">Request for quote</h1>
                                <p class="text-center info">We provide customized pricing for each project and request we receive. Fill the
                                    form below and
                                    receive a personalized quote for your requirements.</p>
                            </div>


                            <div class="email-box text-cente d-flex flex-column">
                                <div class="d-flex justify-content-center mt-4">
                                    <i class='bx bxs-envelope bx-sm'></i>
                                    <p class="ms-3">Email ID</p>
                                </div>
                                <span class="text-center mt-2">support@bitbrains.in</span>
                                <div class="hr text-center">
                                </div>
                                <div class="form-content ms-5 me-5">

                                    {
                                        Success !== ""
                                            ? <div className="alert alert-success mx-3 mx-md-5 mb-5" role="alert" style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', color: '#ffffff', borderWidth: '3px' }}>
                                                <i className='bx bxs-check-circle text-success'></i> &nbsp; {Success}
                                            </div>
                                            : ""
                                    }
                                    {
                                        err !== ""
                                            ? <div className="alert alert-danger mx-3 mx-md-0 mb-5" role="alert" style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', color: '#ffffff', borderWidth: '3px' }}>
                                                <i className='bx bxs-sad text-danger'></i> &nbsp; {err}
                                            </div>
                                            : ""
                                    }


                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <div class="row mt-2">
                                            <div class="col-md-4">
                                                <label for="name">Name :</label>
                                            </div>
                                            <div class="col-md-4 mt-3 mt-md-0">
                                                <input
                                                    type="text"
                                                    class="form-control fname"
                                                    id="fname"
                                                    placeholder="First Name"
                                                    {...register("firstname", {
                                                        required: true,
                                                        pattern: /^[a-zA-ZÀ-ÿ\-'. ]{2,30}$/,
                                                    })}
                                                />
                                                <small className="text-danger">{errors.firstname?.type === 'required' && "First name is required!"}</small>
                                                <small className="text-danger">{errors.firstname?.type === 'pattern' && "Please enter a valid name"}</small>
                                            </div>
                                            <div class="col-md-4 mt-3 mt-md-0">
                                                <input
                                                    type="text"
                                                    class="form-control lname"
                                                    id="lname"
                                                    placeholder="Last Name"
                                                    {...register("lastname", {
                                                        required: true,
                                                        pattern: /^[a-zA-ZÀ-ÿ\-'. ]{2,30}$/,
                                                    })}
                                                />
                                                <small className="text-danger">{errors.lastname?.type === 'required' && "Last name is required!"}</small>
                                                <small className="text-danger">{errors.lastname?.type === 'pattern' && "Please enter a valid name"}</small>
                                            </div>
                                        </div>


                                        <div class="row mt-4">
                                            <div class="col-md-4">
                                                <label for="email">Email :</label>
                                            </div>
                                            <div class="col-md-8 mt-3 mt-md-0">
                                                <input
                                                    type="email"
                                                    class="form-control email"
                                                    id="email"
                                                    placeholder="Enter Your Valid Email"
                                                    {...register("email", {
                                                        required: true,
                                                        pattern: /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/,
                                                    })}
                                                />
                                                <small className="text-danger">{errors.email?.type === 'required' && "Email is required!"}</small>
                                                <small className="text-danger">{errors.email?.type === 'pattern' && "Please enter a valid email"}</small>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col-md-4">
                                                <label for="mnumber">Mobile Number :</label>
                                            </div>

                                            <div class="col-md-8 mt-3 mt-md-0">
                                                <input
                                                    type="text"
                                                    id="phone"
                                                    class="form-control phone"
                                                    placeholder="Enter your phone number"
                                                    {...register("mobile", {
                                                        required: true,
                                                        pattern: /^\+?[0-9]{1,4}[ -]?\(?[0-9]{1,3}\)?[ -]?[0-9]{3,4}[ -]?[0-9]{4}$/
                                                    })}
                                                />
                                                <small className="text-danger">{errors.mobile?.type === 'required' && "Mobile is required!"}</small>
                                                <small className="text-danger">{errors.mobile?.type === 'pattern' && "Please enter a valid mobile format"}</small>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col-md-4">
                                                <label for="service">Service :</label>
                                            </div>
                                            <div class="col-md-8 mt-3 mt-md-0">
                                                <div class="select-wrapper form-control">
                                                    <select
                                                        id="service"
                                                        name="service"
                                                        class="form-control"
                                                        {...register("service", {
                                                            required: true,
                                                        })}
                                                    >
                                                        <option value="" disabled selected>--Select--</option>
                                                        <option value="app_development">App Development</option>
                                                        <option value="crm_erp">CRM & ERP</option>
                                                        <option value="seo">SEO</option>
                                                        <option value="hire_offshore">Hire Offshore Development</option>
                                                        <option value="designing">Designing</option>
                                                        <option value="webdevlopment">Web Development</option>
                                                    </select>
                                                    <span class="dropdown-icon">&#9660;</span>
                                                </div>
                                                <small className="text-danger">{errors.service?.type === 'required' && "Selection is required!"}</small>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col-md-4">
                                                <label for="service">Budget :</label>
                                            </div>
                                            <div class="col-md-8 mt-3 mt-md-0">
                                                <div class="select-wrapper form-control">
                                                    <select
                                                        id="budget"
                                                        name="budget"
                                                        class="form-control"
                                                        {...register("budget", {
                                                            required: true,
                                                        })}
                                                    >
                                                        <option value="" disabled selected>--Select--</option>
                                                        <option value="less than 5000">Less than $5000</option>
                                                        <option value="5000-20000">$5000-$20000</option>
                                                        <option value="20000-50000">$20000-$50000</option>
                                                        <option value="50000-100000">$50000-$100000</option>
                                                        <option value="more than 100000">More than $100000</option>
                                                    </select>
                                                    <span class="dropdown-icon">&#9660;</span>
                                                </div>
                                                <small className="text-danger">{errors.budget?.type === 'required' && "Selection is required!"}</small>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col-md-4">
                                                <label for="start">How Soon You Want Start :</label>
                                            </div>
                                            <div class="col-md-8 mt-3 mt-md-0">
                                                <div class="select-wrapper form-control">
                                                    <select
                                                        id="start"
                                                        name="start"
                                                        class="form-control"
                                                        {...register("start", {
                                                            required: true,
                                                        })}
                                                    >
                                                        <option value="" class="select-bg" disabled selected>--Select--</option>
                                                        <option value="tommorow">Tommorow</option>
                                                        <option value="after_7">After 7 Days</option>
                                                        <option value="after_15">After 15 Days</option>
                                                        <option value="after_20">After 20 Days</option>
                                                        <option value="after_month">After a Month</option>
                                                    </select>
                                                    <span class="dropdown-icon">&#9660;</span>
                                                </div>
                                                <small className="text-danger">{errors.start?.type === 'required' && "Selection is required!"}</small>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col-md-4">
                                                <label for="requirement">Requirement :</label>
                                            </div>
                                            <div class="col-md-8 mt-md-0">
                                                <div class="row mb-3">
                                                    <div class="col-md-6 mt-3 mt-md-0">
                                                        <input
                                                            type="radio"
                                                            class="support_maintenance"
                                                            name="requirement"
                                                            id="support_maintenance"
                                                            value="support_maintenance"
                                                            style={{ height: '15px', width: '15px' }}
                                                            {...register("requirement", {
                                                                required: true,
                                                            })}
                                                        />
                                                        <label for="support_maintenance" class=" ms-2">Support maintenance </label>
                                                    </div>
                                                    <div class="col-md-6 mt-3 mt-md-0">
                                                        <input
                                                            type="radio"
                                                            name="requirement"
                                                            class="new_project"
                                                            id="new_project"
                                                            value="new_project"
                                                            style={{ height: '15px', width: '15px' }}
                                                            {...register("requirement", {
                                                                required: true,
                                                            })}
                                                        />
                                                        <label for="new_project" class="ms-2"> New project </label>
                                                    </div>

                                                    <div class="col-md-6 mt-3 mt-md-0">
                                                        <input
                                                            type="radio"
                                                            name="requirement"
                                                            class="existing_project"
                                                            id="existing_project"
                                                            value="existing_project"
                                                            style={{ height: '15px', width: '15px' }}
                                                            {...register("requirement", {
                                                                required: true,
                                                            })}
                                                        />
                                                        <label for="existing_project" class="ms-2"> Existing project</label>
                                                    </div>
                                                    <div class="col-md-6 mt-3 mt-md-0">
                                                        <input
                                                            type="radio"
                                                            name="requirement"
                                                            class="hire_d_"
                                                            id="hire_d_"
                                                            value="hire_developer"
                                                            style={{ height: '15px', width: '15px' }}
                                                            {...register("requirement", {
                                                                required: true,
                                                            })}
                                                        />
                                                        <label for="hire_d_t" class=" ms-2">
                                                            Hire Dedicated Team
                                                        </label>
                                                    </div>
                                                </div>
                                                <small className="text-danger">{errors.requirement?.type === 'required' && "Selection is required!"}</small>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col-md-4">
                                                <label for="description">Brief Project Description :</label>
                                            </div>
                                            <div class="col-md-8 mt-3 mt-md-0">
                                                <textarea
                                                    class="form-control"
                                                    name="description"
                                                    id="description"
                                                    placeholder="Brief Project Description"
                                                    rows="3"
                                                    {...register("description", {
                                                        required: true,
                                                    })}
                                                ></textarea>
                                                <small className="text-danger">{errors.description?.type === 'required' && "Description is required!"}</small>
                                            </div>
                                        </div>

                                        <div class="row mt-4  mb-4 text-center">
                                            <div class="col-md-12">
                                                {
                                                    loader === true
                                                        ? <button name="submit" class="submit btn-main" disabled>Waiting...</button>
                                                        : <button type="submit" name="submit" class="submit btn-main" id="submit">Submit</button>
                                                }

                                            </div>

                                        </div>

                                    </form>

                                </div>
                            </div>


                        </div>

                    </div>
                </section>




                <div className="footer">
                    <div className="container wrapper">
                        <div className="d-flex flex-column align-items-center mb-5">
                            <div className="footer-box d-flex flex-column align-items-center">
                                <img src={bb} className="img-fluid text-center" height="50px" width="50px" />
                                <h2 className="text-center">Let's Connect<br /><span className="color_text text-capitalize text-center">with US.</span></h2>
                                {/* <form>
                                    <input type="email" className="email" placeholder="Insert your email" required />
                                    <button type="submit" className="btn-color">Request Demo</button>
                                </form> */}
                            </div>
                        </div>
                    </div>

                    <div className="footer-final container">
                        <div className="row g-5">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                                <h3>About</h3>
                                <p>At BitBrains, we pride ourselves on being the top choice for all your digital needs. With years of experience and a passionate team of experts, we go above and beyond to deliver exceptional results. What sets us apart is our unwavering dedication to our clients.</p>
                            </div>


                            <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                                <div className="d-flex flex-column justify-content-center">
                                    <h3>Our Service</h3>
                                    <p>App Development</p>
                                    <p>Web Development</p>
                                    <p>ERP & CRM</p>
                                    <p>Designing</p>
                                    <p>Hire Offshore Developers</p>
                                </div>

                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                                <h3>Email</h3>
                                <p>support@bitbrains.in</p>
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                                <h3>Want to talk about your project?
                                </h3>
                                <a className="btn-main mt-4" href="https://calendly.com/vivekbodarya" target='_blank'>Schedual A Call</a>
                            </div>
                        </div>
                    </div>
                    <div className="copyright container">
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-6 mb-4'>
                                <p className="ms-3 mt-4">Copyright @2024 Bitbrains Infotech</p>
                            </div>
                            <div className='col-12 col-sm-12 col-md-6 mb-4 d-flex justify-content-end'>
                                <div className="social-media me-4 mt-2">
                                    <a href="https://www.linkedin.com/in/vivekbodarya/" target='_blank'>
                                        <i className='bx bxl-linkedin'></i>
                                    </a>
                                    <a href="https://www.instagram.com/bitbrains.in" target='_blank'>
                                        <i className='bx bxl-instagram'></i>
                                    </a>
                                    <a href="https://dribbble.com/vivek_bodarya" target='_blank'>
                                        <i className='bx bxl-dribbble'></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <div class="d-flex justify-content-between align-items-center">
                            <p class="ms-3">Copyright @2024 Bitbrains Infotech</p>
                            <div class="social-media me-4">
                                <a href="https://www.linkedin.com/in/vivekbodarya/" target='_blank'>
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                                <a href="https://www.instagram.com/bitbrains.in" target='_blank'>
                                    <i class='bx bxl-instagram'></i>
                                </a>
                                <a href="https://dribbble.com/vivek_bodarya" target='_blank'>
                                    <i class='bx bxl-dribbble'></i>
                                </a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequestQuote